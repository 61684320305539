import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaiduMap from 'vue-baidu-map'
import store from './store'
import {
  Icon, Carousel, CarouselItem, Message, Popover, Row,
  Col, MessageBox, Dialog, Tabs, TabPane, Collapse,
  CollapseItem, Progress, Pagination, Image, Input, Form, FormItem, Button, Loading
} from 'element-ui'
import './assets/theme/index.css'
import 'element-ui/lib/theme-chalk/index.css'
import storage from '@/utils/storage'// 引入
import 'wowjs/css/libs/animate.css'

import Directive from './directives'

Vue.use(BaiduMap, { ak: 'dhcUkwCVnVdFmK4kd2ue5jz0FICAXmp3' })

Vue.use(Directive)

Vue.prototype.$storage = storage// 加入vue的原型对象

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Progress)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(Loading)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

Vue.config.productionTip = false

router.afterEach(() => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
